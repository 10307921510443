<template>
  <div class="coupon-list">
    <!-- <el-tabs v-if="bqList && bqList.length > 0" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane v-for="(item, index) in bqList" :key="index" :label="item.typeName" :name="item.uid"></el-tab-pane>
    </el-tabs> -->
    <!-- <div class="tabs">
      <div v-for="(item, index) in bqList" :key="index" class="tab-item" :class="tabActive == index ? 'on' : ''"
        @click="changeTabs(index)">
        {{ item.typeName }}
      </div>
    </div> -->
    <div class="courseName">
      <div class="courseName-box" id="courseNameBox">
        <div :id="'courseName' + index" ref="courseNameItems"
          :class="{ 'courseName-item': true, 'courseName-check': courseNameId == item.uid }"
          v-for="(item, index) in bqList" :key="index" @click="chooseCourseName(item, index)">
          {{ item.typeName }}
        </div>
      </div>
    </div>
    <div class="coupon-item" v-for="(item, index) in keDataList" :key="index">
      <!--   <i class="iconfont icon-a-zu939 img" /> -->
      <img v-if="item.filetype == 'excel'" class="img" src="@/assets/img/study/dataXls2.png" />
      <img v-else-if="item.filetype == 'word'" class="img" src="@/assets/img/study/dataDoc2.png" />
      <img v-else-if="item.filetype == 'pdf'" class="img" src="@/assets/img/study/dataPdf2.png" />
      <img v-else-if="item.filetype == 'ppt'" class="img" src="@/assets/img/study/dataPpt2.png" />
      <img v-else class="img" src="@/assets/img/study/dataFile2.png" />
      <div>
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <div class="item-content">
            <div class="item-title ellipsis">{{ item.fileName }}</div>
            <div class="item-size ellipsis">{{ item.size }}</div>
          </div>
          <div class="keep-learn" @click="delow(item)">下载</div>
        </div>
        <div style="width: 100%;height: 1px;background:  #EEEEEE;margin-top: 14px;margin-left: 10px;"></div>
      </div>
    </div>
    <div class="nothing" v-if="!keDataList || keDataList.length == 0">
      <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
      <div class="nothing-text">暂无资料哦~</div>
    </div>
    <el-dialog :visible.sync="kowledgePointsVisible" width="50%" :before-close="kowledgePointsClose" :show-close="false"
      append-to-body>
      <div class="kowledgePoints_wrap">
        <kowledgePoints ref="kowledgePoints" type="4"></kowledgePoints>
        <div class="determine_btn" @click="kowledgePointsClose">确定</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { Know } from '@/api/know'
let know = new Know()
import { getInfo, getToken } from '@/api/cookies'
import { DataClient } from '@/api/dataClient'
let dataClient = new DataClient()
import kowledgePoints from '@/components/kowledgePoints.vue'
export default {
  data() {
    return {
      userInfo: null,
      keDataList: [],
      dialogVisible: false,
      kowledgePointsVisible: false,
      bqList: [],
      activeName: null,
      courseNameId: '',
      tabActive: 0
    }
  },
  components: {
    kowledgePoints,
  },
  props: {
    isPurchase: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String | Number,
    },
    type: {
      type: Number /* 1 直播 2录播 3音频 4 图文 5面授 */,
    },
    newproductLine: {
      type: String | Number,
    },
    newkcId: {
      type: String | Number,
    },
  },
  created() {
    let that = this
    this.userInfo = getInfo()


    if (!this.id) {
      return
    }
    know.getDocsPcLabelList({ oneLabelId: this.newproductLine, kcId: this.newkcId }).then((res) => {
      res.data.forEach(e => {
        e.uid = e.id.toString()
      });
      if (res.data && res.data.length > 0) {
        res.data.unshift({
          uid: '',
          typeName: '全部'
        });
      }
      this.bqList = res.data

      this.getDocsPcPageList()
    })

  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
      this.getDocsPcPageList()
    },
    changeTabs(val) {
      this.tabActive = val
      this.activeName = this.bqList[val].uid
      this.getDocsPcPageList()
    },
    getDocsPcPageList() {
      let that = this
      know.getDocsPcPageList({ oneLabelId: this.newproductLine, twoLabelId: this.activeName && this.activeName != 0 ? this.activeName : undefined, kcId: this.newkcId }).then((res) => {
        this.keDataList = res.data
        for (let item of this.keDataList) {
          item.filetype = Vue.prototype.getFileType(item.filePath)
          Vue.prototype.getFilesize(item.filePath, function (size) {
            let sizeVal = (size / 1028).toFixed(0) + 'k'
            let data = (size / 1028 / 1024).toFixed(0)
            item.size = data > 0 ? data + 'M' : sizeVal
            that.$forceUpdate()
          })
        }
      })
    },
    /* 下载 */
    delow(item) {

      let userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
      if (this.isPurchase) {
        let data = {
          zlId: item.id,
          linkLocation: item.filePath,
          name: item.fileName,
          type: 0,
        }
        dataClient.downloadRecord(data)
        Vue.prototype.DownloadDoc(item.filePath, item.fileName)
      } else {
        this.$notify({
          title: '错误',
          message: '未购买，无法下载, 请先去购买',
          type: 'error',
          duration: 2000,
        })
      }
    },
    openKowledge(item) {
      this.kowledgePointsVisible = true
      this.$nextTick(() => {
        this.$refs.kowledgePoints.getData(item.id)
      })
    },
    kowledgePointsClose() {
      this.kowledgePointsVisible = false
    },

    // 课程名
    async chooseCourseName(item, index) {
      this.courseNameId = item.uid;
      this.activeName = item.uid;
      sessionStorage.setItem("questionBank-courseNameId", this.courseNameId);
      this.courseNameInfo = item;
      const courseNameBox = document.getElementById("courseNameBox");
      if (index != 0) {
        index--;
        const courseNameItem = this.$refs.courseNameItems[index];
        courseNameBox.scrollLeft = courseNameItem.offsetLeft;
      }
      this.changeCourseNameShow = false;
      await this.getDocsPcPageList()

    },
    // 切换课程名
    showChangeCourseName() {
      this.changeCourseNameShow = !this.changeCourseNameShow;
    },
  },
}
</script>
<style lang="less" scoped>
/* 随堂资料 */
.coupon-list {
  width: 680px;
  height: 419px;
  background: #FFFFFF;
  border-radius: 20px 20px 20px 20px;
  margin: 0 auto;
  margin-top: 15px;
  padding: 20px;
  overflow-y: scroll;

  .coupon-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .img {
      width: 50px;
      height: 50px;
      flex-shrink: 0;
    }

    .item-content {
      text-align: left;
      margin: 0px 12px;

      .item-title {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #333333;
        line-height: 16px;
        width: 500px;
      }

      .item-size {
        margin-top: 12px;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #999999;
        line-height: 12px;
      }
    }

    .btn_item {
      flex-shrink: 0;
      width: 74px;
      height: 30px;
      font-size: 14px;
      color: #666666;
      border: 1px solid #dddddd;
      text-align: center;
      border-radius: 4px;
      line-height: 30px;
      margin-right: 20px;
      cursor: pointer;
    }

    .keep-learn {
      flex-shrink: 0;
      width: 60px;
      height: 30px;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #2586F5;
      display: flex;
      align-items: center;
      justify-content: center;

      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 14px;
      color: #2586F5;

      &:hover {
        background: #2586F5;
        color: #FFFFFF;
      }
    }
  }

  .kowledgePoints_wrap {
    padding-bottom: 20px;

    .determine_btn {
      width: 40%;
      height: 40px;
      margin: 0 auto;
      margin-top: 20px;
      background-color: #ff5e51;
      color: #ffffff;
      font-weight: 500;
      border-radius: 20px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
    }
  }
}

::v-deep .el-tabs__nav-wrap::after {
  height: 0 !important;


}

::v-deep .el-tabs__active-bar {
  // width: 18px !important;
  // height: 4px !important;
  background: #2586F5 !important;
  border-radius: 4px 4px 4px 4px !important;
  margin: 0 auto;
  // padding: 0 24px;
  // box-sizing: border-box !important;
  // background-clip: content-box !important;
  // transform: translateX(50%) !important;

  // position: relative;
  // left: 50%;
}

// ::v-deep .el-tabs__nav {
//   position: relative;
// }
.tabs {
  display: flex;
  align-items: center;
  color: #666666;
  font-size: 14px;
  // width: 272px;
  // height: 38px;
  background: #f5f7f9;
  border-radius: 6px 6px 6px 6px;

  .tab-item {
    cursor: pointer;
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #5a5d5a;
    text-align: center;
    // width: 132px;
    // height: 38px;
    // line-height: 38px;
    padding: 5px 10px;
  }

  .on {
    color: #ff5e51;
    width: 132px;
    height: 30px;
    background: #ffffff;
    border-radius: 6px 6px 6px 6px;
    line-height: 30px;
    margin: 4px;
  }
}

.courseName {
  // padding: 0 24px;
  margin-bottom: 28px;
  display: flex;

  .courseName-box {
    flex: 1;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    position: relative;
    transition: all 0.3s;

    .courseName-item {
      display: inline-block;
      height: 30px;
      line-height: 30px;
      font-size: 15px;
      color: #333;
      border-radius: 4px;
      padding: 0 10px;
      margin-right: 8px;
      cursor: pointer;
      background: #EDF0F7;
    }

    .courseName-check {
      background-color: #E8F3FE;
      color: #2586F5;
      border: 1px solid #2586F5;
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  .courseName-change {
    padding: 0 8px;
    height: 36px;
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #21385a;
    cursor: pointer;

    img {
      width: 15px;
      height: 15px;
      margin-right: 6px;
    }
  }
}
</style>
