<!--
 * @Author: 
 * @Date: 2023-02-21 09:49:59
 * @LastEditTime: 2023-08-29 09:27:59
 * @LastEditors: Please set LastEditors
 * @Description: 
 * @FilePath: \edub_tob_pc\src\views\partnerPage\mine\learn\coursesDetails.vue
 * 
-->
<template>
  <!-- 我的课程 -->
  <div class="foxRight">
    <!-- 有课程 -->
    <div class="classView">
      <div class="bottom_box bottom_boxs">
        <div class="chapter_top">
          <div class="chapter_title">
            <img class="tips" src="@/assets/img/partner/tips.png" alt="" />
            <img v-if="type == 2" class="tit" src="@/assets/img/partner/tit.png" alt="" />
            <img v-if="type == 3" class="tit" src="@/assets/img/partner/video.png" alt="" />
            <span style="max-width: 600px;" class="elp">{{ name }}</span>
          </div>
          <div class="chapter_btn">
            <div v-if="courseTestFlag == 1" class="text_btn" @click="textClick(1)">课程测试</div>
            <div v-if="courseDocFlag == 1" class="data_btn" @click="textClick(2)">课程资料</div>
          </div>
        </div>
        <div v-for="(child, childIndex) in chapterList" v-if="type == 10" :key="childIndex" class="chapter_item">
          <div class="left_box">
            <div class="title_box">
              <div v-if="type != 10" :class="child.isLiveStatus == 0 ? 'tag tags' : 'tag'">
                <template v-if="child.isLiveStatus == 1">
                  <img class="tag_img" src="@/assets/img/partner/bofang.png" alt="" />
                  <span>直播中</span>
                </template>
                <template v-else-if="child.isLiveStatus == 0">
                  <!-- <img
                    class="tag_img"
                    src="@/assets/img/homeSeventh/playIcon2.png"
                    alt=""
                  /> -->
                  <span>直播未开始</span>
                </template>
                <template v-else-if="child.isLiveStatus == 2">
                  <!-- <img
                    class="tag_img"
                    src="@/assets/img/homeSeventh/playIcon3.png"
                    alt=""
                  /> -->
                  <span style="color: #ffac87">回放生成</span>
                </template>
                <template v-else-if="child.isLiveStatus == 3">
                  <img class="tag_img" src="@/assets/img/homeSeventh/playIcon4.png" alt="" />
                  <span>观看回放</span>
                </template>
              </div>
              <div class="title ">
                {{ child.name }}
              </div>
            </div>
            <div class="time">
              <img class="time_img" src="@/assets/img/homeSeventh/clockAndWatch.png" alt="" />
              <span>{{ child.startTime | format('yyyy.MM.dd HH:mm') }}</span>
              <!-- <span>-</span>
                  <span>{{ child.endTime | format("HH:mm") }}</span> -->
            </div>
            <div v-if="type != 10" class="progress_box">
              <div class="progress_bar">
                <div class="schedule" :style="`width: ${child.studyRate}%`" />
              </div>
              <span class="text">{{ child.studyRate }}%</span>
            </div>
          </div>
          <div class="right_box">
            <div v-if="child.teachList && child.teachList.length > 0" class="btn_item" @click.stop="goData(child, 2)">
              随堂讲义
            </div>
            <div v-if="child.testList && child.testList.length > 0" class="btn_item" @click.stop="cgeckTask(child)">
              随堂测试
            </div>
            <div v-if="child.resourceList && child.resourceList.length > 0" class="btn_item"
              @click.stop="goData(child, 1)">
              随堂资料
            </div>
          </div>
        </div>
        <div v-for="(child, childIndex) in chapterList" v-if="type == 2" :key="childIndex" class="chapter_item zb">
          <div class="arriveBox">
            <img v-if="child.isDkStudy == 1" src="@/assets/img/partner/arrive.png" alt="" />
            <img v-else src="@/assets/img/partner/unArrive.png" alt="" />
            <!-- <img src="@/assets/img/partner/unArrive.png" alt=""> -->
          </div>
          <div class="left_box">
            <div class="title_box">
              <div class="titleImg">
                <img src="@/assets/img/partner/live.png" alt="" />
              </div>
              <div class="title" @click.stop="check(child)">
                {{ child.name }}
              </div>
              <div v-if="type == 2" :class="[
                child.isLiveStatus == 3 || child.isLiveStatus == 1
                  ? 'tag tags'
                  : 'tag',
                child.isLiveStatus == 1 || child.isLiveStatus == 2
                  ? 'bdn'
                  : ''
              ]">
                <template v-if="child.isLiveStatus == 1">
                  <img class="tag_img" src="@/assets/img/partner/bofang.png" alt="" />
                  <span>正在直播中</span>
                </template>
                <template v-else-if="child.isLiveStatus == 0">
                  <!-- <img
                    class="tag_img"
                    src="@/assets/img/homeSeventh/playIcon2.png"
                    alt=""
                  /> -->
                  <span>直播未开始</span>
                </template>
                <template v-else-if="child.isLiveStatus == 2">
                  <!-- <img
                    class="tag_img"
                    src="@/assets/img/homeSeventh/playIcon3.png"
                    alt=""
                  /> -->
                  <span>回放生成中</span>
                </template>
                <template v-else-if="child.isLiveStatus == 3">
                  <!-- <img
                    class="tag_img"
                    src="@/assets/img/homeSeventh/playIcon4.png"
                    alt=""
                  /> -->
                  <span>观看回放</span>
                </template>
              </div>
            </div>
            <!-- <div class="time">
              <img
                class="time_img"
                src="@/assets/img/homeSeventh/clockAndWatch.png"
                alt=""
              />
              <span>{{ child.startTime | format("yyyy.MM.dd HH:mm") }}</span>
              <span>-</span>
                  <span>{{ child.endTime | format("HH:mm") }}</span>
            </div> -->
            <div class="progress_box">
              <div class="progress_bar">
                <div class="schedule" :style="`width: ${child.studyRate}%`" />
              </div>
              <span class="text" :style="{ color: child.isTrial ? '#2871ff' : '#666' }">{{ child.studyRate }}%</span>
            </div>
          </div>
          <div class="right_box" :class="child.isDkStudy == 1 ? '' : 'noAttendan'">
            <!-- <div class="btn_item" @click.stop="check(child)">观看课程</div> -->
            <div v-if="child.teachList && child.teachList.length > 0" class="btn_item" @click.stop="goData(child, 2)">
              随堂讲义
            </div>
            <div v-if="child.testList && child.testList.length > 0" class="btn_item" @click.stop="cgeckTask(child)">
              随堂测试
            </div>
            <div v-if="child.resourceList && child.resourceList.length > 0" class="btn_item"
              @click.stop="goData(child, 1)">
              随堂资料
            </div>
          </div>
        </div>
        <div v-for="(childs, childIndexs) in chapterList" v-if="type == 3" :key="childIndexs" class="zhang">
          <div class="zhang_item">
            <div class="titleImg">
              <img src="@/assets/img/partner/live.png" alt="" />
            </div>
            <div class="zhang_item_name elp">{{ childs.name }}</div>
            <!-- <div class="right_btn_course">
              <div v-if="courseDocFlag" @click="kczl()">课程资料</div>
              <div v-if="courseTestFlag == 1" @click="kccs()">课程测试</div>
            </div> -->
            <div v-if="!childs.isshow" class="zhang_item_last" @click="ifshow(childs, 1)">
              <img style="height: 16px; width: 8px; margin-top: 3px" src="@/assets/img/partner/arrow.png" alt="" />
            </div>
            <div v-if="childs.isshow" class="zhang_item_last" @click="ifshow(childs, 2)">
              <img style="height: 8px; width: 16px; margin-top: 8px" src="@/assets/img/partner/down.png" alt="" />
            </div>
          </div>
          <div v-for="(child, childIndex) in gchapterVOList" v-show="childs.isshow" :key="childIndex"
            class="chapter_item chapter_items">
            <div class="left_box">
              <div class="title_box">
                <div class="title dis_flex_start elp" @click.stop="checks(child, childIndexs)">
                  <div class="titleImg" style="transform: translateY(3px)">
                    <img src="@/assets/img/partner/live.png" alt="" />
                  </div>
                  <div style="max-width: 450px;" class="elp">{{ child.name }}</div>

                </div>
              </div>
              <!-- <div class="time">
                  <img
                    class="time_img"
                    src="@/assets/img/homeSeventh/clockAndWatch.png"
                    alt=""
                  />
                  <span>{{
                    child.startTime | format("yyyy.MM.dd HH:mm")
                  }}</span>
                  <span>-</span>
                  <span>{{ child.endTime | format("HH:mm") }}</span>
                </div>-->
              <div class="progress_box">
                <div class="progress_bar">
                  <div class="schedule" :style="`width: ${child.studyRate}%`" />
                </div>
                <span class="text">{{ child.studyRate }}%</span>
              </div>
            </div>
            <div class="right_box">
              <!-- <div class="btn_item" @click.stop="checks(child, childIndexs)">
                观看课程
              </div> -->
              <div v-if="child.teachList && child.teachList.length > 0" class="btn_item" @click.stop="goData(child, 2)">
                随堂讲义
              </div>
              <div v-if="child.testList && child.testList.length > 0" class="btn_item" @click.stop="cgeckTask(child)">
                随堂测试
              </div>
              <div v-if="child.resourceList && child.resourceList.length > 0" class="btn_item"
                @click.stop="goData(child, 1)">
                随堂资料
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="课程资料" :visible.sync="dialogVisible">
      <CourseData v-if="dialogVisible" :id="courseId" :type="types" :is-purchase="true" />
    </el-dialog>
    <el-dialog title="课程测试" :visible.sync="dialogVisibles">
      <course-task v-if="dialogVisibles" :id="courseId" :type="types" :is-purchase="true" />
    </el-dialog>
    <!-- 是否可以观看弹窗 -->
    <div v-if="showLookDetail" class="lookBg">
      <div class="ifLook">
        <div class="prompt">提示</div>
        <div class="contents">
          您当前正在<span>{{ lookDeatil ? lookDeatil.terminal : '' }}</span>观看<span>{{ lookDeatil ? lookDeatil.chapterName : ''
          }}</span>,请关闭后再行观看其他章节。
        </div>
        <div class="button" @click="closeLook">关闭</div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import { getInfo } from '@/api/cookies'
import {
  getClassCourseListById,
  selectStuCourseChapterDetails,
  selectChapterRateBykeIdStudentIdGroupId
} from '@/api/mykecheng'
import Vue from 'vue'
import { showTypeEnums } from '@/api/emun'
import { AnswerClient } from '@/api/task/answerClient'
const answerClient = new AnswerClient()
import CourseData from '@/components/Know/course/courseData.vue'
import courseTask from '@/components/Know/course/courseTask.vue'
import { courseCheckPlay } from '@/api/know'
export default {
  components: { CourseData, courseTask },
  data() {
    return {
      list: [],
      tuid: 8,
      tel: '',
      //  1知识套餐
      type: 3,
      types: '',
      total: 0,
      pageSize: 8,
      pageNum: 1,
      userId: '',
      showTypeEnums: showTypeEnums,
      userInfo: null,
      classRate: {},
      datafrom: {},
      btnInfo: 0,
      courseType: null,
      courseTypes: null,
      courId: null,
      name: null,
      gchapterVOList: [],
      chapterList: [],
      courseTestFlag: null,
      dialogVisible: false,
      dialogVisibles: false,
      kmsg: {},
      lookDeatil: null,
      showLookDetail: false,
      items: {}
    }
  },
  created() {
    this.userInfo = getInfo()
    console.log(this.$route.query, 'this.$route.query');
    this.items = this.$route.query
    this.type = this.$route.query.type
    this.types = this.$route.query.types
    this.courseTestFlag = this.$route.query.courseTestFlag
    this.courseDocFlag = this.$route.query.courseDocFlag
    this.courseId = this.$route.query.courseId
    this.details = JSON.parse(this.$route.query.details)
    this.studentId = this.$route.query.studentId
    this.name = this.$route.query.name
    this.userId = localStorage.getItem('userId')
    if (!this.userId) {
      Vue.prototype.goLoginView('mustBack')
      return
    }
    // 调用我的课程接口
    // this.getList();

    this.getLists()
  },
  methods: {
    // 课程测试、课程资料
    textClick(val) {
      if (val === 1) {
        this.dialogVisibles = true

      } else if (val === 2) {
        this.dialogVisible = true

      }
    },
    // 去选课
    allopenclass() {
      this.$router.push(`/allcurriculum/typeclass`)
    },
    getLists() {
      selectStuCourseChapterDetails(this.$route.query).then((res) => {
        if (res.code !== 0) return
        res.data.forEach((e) => {
          if (e.gchapterVOList && e.gchapterVOList.length > 0) {
            e.isshow = false
          }
        })
        this.chapterList = res.data
        // this.$set(item, "chapterList", res.data);
      })
    },
    changeTabs(index) {
      this.type = index
      this.btnInfo = index
      this.pageNum = 1
      this.list = []
      this.pageSize = 8
      this.getList()
    },
    /* 随堂资料 */
    goData(item, type) {
      const userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }

      window.open(
        `/data/dataPackageDetails?courId=${this.courseId}&courseType=${this.items.types
        }&type=${this.courseType == 1 ? 2 : 1}&docType=${type}&ChatId=${item.id
        }&groupId=${this.type == 3 ? item.groupId : undefined}`,
        '_blank'
      )
    },
    // 课堂作业
    cgeckTask(item) {
      const title = item.testList[0].sjName
      const sId = item.testList[0].cContactid
      answerClient.selectPaperSubjectList(sId).then((res) => {
        if (res.code == 0) {
          Vue.prototype.goExams(title, sId, 2, 1, true, res.data.paper.seeNum)
        }
      })
    },
    ifshow(row, i) {
      this.gchapterVOList = []
      if (i == 1) {
        selectChapterRateBykeIdStudentIdGroupId({
          keId: this.courseId,
          groupId: row.id,
          studentId: this.userInfo.id
        }).then(async (res) => {
          if (res.code !== 0) return
          await this.chapterList.forEach((e) => {
            e.isshow = false
          })
          this.gchapterVOList = res.msg
          console.log(res.msg)
          row.isshow = true
        })
      } else {
        row.isshow = false
      }
      this.$forceUpdate()
    },
    // 观看重播按钮  切换课程章节播放(直播)
    check(item) {
      const userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }

      // if (this.isFreeze == 1) {
      //   this.$message.error('课程已冻结')
      //   return
      // }
      if (item.isLive == 0) {
        this.$message.error('课程还未到开播时间')
        return
      }
      if (item.isLive == 2) {
        this.$message.error('课程直播结束')
        return
      }

      const sub = {
        id: item.id,
        startTime: item.startTime,
        chapterName: item.name,
        classProductLine: this.details.productLine
      }
      for (const key in sub) {
        if (!sub[key] && sub[key] !== 0) {
          delete sub[key]
        }
      }
      const data = Vue.prototype.export(sub)
      // this.$router.push({
      //   path: `/livebofang?${data}`,
      // })
      courseCheckPlay({
        courseType: this.courseType,
        userId: this.userInfo.id,
        chapterId: item.id,
        source: 1
      }).then((res) => {
        if (res.code == 0) {
          const routeUrl = this.$router.resolve({
            path: `/livebofang?${data}`
          })
          window.open(routeUrl.href, '_blank')
        } else {
          this.lookDeatil = res.msg
          this.showLookDetail = true
        }
      })
    },
    // 观看回放（录播）
    checks(item, index) {
      const userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
      if (this.isFreeze == 1) {
        this.$message.error('课程已冻结')
        return
      }

      if (this.isOutsourcing == 1) {
        this.ifDialog = true
        this.dialogList = item
      } else {
        const sub = {
          id: item.id,
          uservideoConfig: item.videoConfig,
          groupId: this.courId,
          classId: null,
          typebs: 2,
          courseId: item.courseId,
          chapterName: item.name,
          classProductLine: this.details.productLine
        }
        const data = Vue.prototype.export(sub)
        courseCheckPlay({
          courseType: this.courseType,
          userId: this.userInfo.id,
          chapterId: item.id,
          source: 1
        }).then((res) => {
          if (res.code == 0) {
            console.log(data)
            this.$router.push(`/dianbofangs?${data}&openIndex=${index}`)
          } else {
            this.lookDeatil = res.msg
            this.showLookDetail = true
          }
        })
      }
    },
    kczl() {
      if (this.details.isFreeze == 1) {
        this.$message.error('课程已冻结')
        return
      }
      if (this.details.isDelete == 0) {
        this.$message({
          message: '课程已删除,请联系老师',
          type: 'error'
        })
        return
      }
      if (this.details.isEliminate == 1) {
        this.$message({
          message: '课程已退费,请联系老师',
          type: 'error'
        })
        return
      }
      if (
        this.calculateTheValidityPeriod(this.details.validityTime) == '已过期'
      ) {
        this.$message.error('课程已到期')
        return
      }
      this.courseType = this.details.types
      this.courId = this.details.coursesId

      this.dialogVisible = true
    },
    closeLook() {
      this.showLookDetail = false
    },
    calculateTheValidityPeriod(time) {
      const currentTime = new Date()
      time = time ? time.toString().replace(/-/g, '/') : time
      const targetTime = new Date(time)
      const difference = targetTime.getTime() - currentTime.getTime()
      if (difference < 0) {
        return '已过期'
      }
      const year = Math.floor(difference / 1000 / 60 / 60 / 24 / 365)
      const days =
        Math.floor(difference / 1000 / 60 / 60 / 24) - Math.floor(year * 365)
      // if ( year > 0 && days > 0 ) {
      //     return year + '年' + days + '天'
      // } else if ( year <= 0 && days > 0 ) {
      //     return days + '天'
      // }
      return year + '年' + days + '天'
    },
    kccs() {
      if (this.details.isFreeze == 1) {
        this.$message.error('课程已冻结')
        return
      }
      if (this.details.isDelete == 0) {
        this.$message({
          message: '课程已删除,请联系老师',
          type: 'error'
        })
        return
      }
      if (this.details.isEliminate == 1) {
        this.$message({
          message: '课程已退费,请联系老师',
          type: 'error'
        })
        return
      }
      if (
        this.calculateTheValidityPeriod(this.details.validityTime) == '已过期'
      ) {
        this.$message.error('课程已到期')
        return
      }
      this.courseType = this.details.types
      this.courId = this.details.coursesId
      this.dialogVisibles = true
    }
  }
}
</script>
<style lang="less" scoped>
.foxRight {
  background: #fff;
  height: 100%;
  padding-bottom: 20px;
}

.bottom_box {
  // width: 1010px;
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 8px;

  .chapter_top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .chapter_title {
      height: 57px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .tips {
        // width: 25px;
        height: 26px;
        transform: translate(-6px, 4px);
      }

      .tit {
        width: 48px;
        margin-right: 10px;
      }

      span {
        height: 16px;
        line-height: 16px;
        font-size: 16px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #333333;
      }
    }

    .chapter_btn {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 60px;

      .text_btn,
      .data_btn {
        width: 72px;
        height: 24px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #2586F5;
        font-size: 12px;
        font-family: MicrosoftYaHei-, MicrosoftYaHei;
        font-weight: normal;
        color: #2586F5;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .text_btn {
        margin-right: 12px;
      }
    }
  }


  // margin-top: 15px;
  .chapter_item {
    position: relative;
    width: 100%;
    height: 80px;
    // background: #f8f9fa;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    margin-bottom: 20px;
    padding: 20px 27px 0px 15px;
    // border-bottom: 1px solid #eeeeee;
    display: flex;
    justify-content: space-between;

    .arriveBox {
      position: absolute;
      top: 0;
      right: 20px;
      width: 54px;

      img {
        width: 100%;
      }
    }

    .left_box {
      flex: 1;
      // width: 300px;

      .title_box {
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        .titleImg {
          width: 16px;
          margin-right: 14px;
          height: 16px;

          img {
            width: 100%;
          }
        }

        .tag {
          border-radius: 3px 3px 3px 3px;
          opacity: 1;
          display: inline-block;
          padding: 2px 8px;
          height: 22px;
          border-radius: 3px 3px 3px 3px;
          opacity: 1;
          border: 1px solid #b9bfc4;
          background-color: rgba(185, 191, 196, 0);
          margin-right: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 400;
          color: #777a82;
          flex-shrink: 0;

          .tag_img {
            width: 11px;
            margin-right: 3px;
          }
        }

        .title {
          color: hsl(0, 0%, 20%);
          max-width: 700px;
          overflow: hidden;
          margin-right: 10px;
          white-space: nowrap;
          text-overflow: ellipsis;
          cursor: pointer;
        }
      }

      .time {
        color: #aaaaaa;
        font-size: 14px;
        display: flex;
        align-items: center;
        margin-bottom: 19px;

        .time_img {
          width: 14px;
          height: 14px;
          margin-right: 6px;
          flex-shrink: 0;
        }
      }

      .progress_box {
        width: 400px !important;
        display: flex;
        align-items: center;
        margin-left: 30px;

        .progress_bar {
          width: 200px;
          height: 10px;
          background: #f4f4f4;
          border-radius: 5px 5px 5px 5px;
          opacity: 1;

          .schedule {
            background: linear-gradient(90deg, #a5bdff 0%, #2871ff 100%);
            height: 8px;
            width: 0;
            border-radius: 10px;
          }
        }

        .text {
          font-size: 12px;
          color: #2871ff;
          margin-left: 8px;
        }
      }
    }

    .right_box {
      display: flex;
      align-items: center;
      // margin-right: 28px;

      .btn_item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        width: 72px;
        height: 24px;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        margin-right: 12px;
        border: 1px solid #2586f5;
        font-size: 12px;
        font-family: MicrosoftYaHei-, MicrosoftYaHei;
        font-weight: normal;
        color: #2586f5;
        cursor: pointer;

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  .chapter_item:last-child {
    border-bottom: 0;
  }
}

.bottom_boxs {
  background-color: #fff;

  .chapter_item {
    // background-color: #f9f9f9;
  }

  .zhang {
    margin-bottom: 20px;
  }

  .zhang_item {
    width: 1045px;
    height: 60px;
    background: #f8f9fa;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    display: flex;
    align-items: start;
    padding: 18px 20px 0 15px;

    .titleImg {
      width: 16px;
      margin-right: 14px;
      height: 16px;
      transform: translateY(3px);

      img {
        width: 100%;
      }
    }

    .time_img {
      display: block;
      width: 14px;
      height: 12px;
      margin-right: 8px;
      flex-shrink: 0;
    }

    .zhang_item_name {
      flex: 1;
      max-width: 800px;
      margin-right: 200px;

    }

    .right_btn_course {
      display: flex;
      justify-content: flex-end;
      margin-right: 28px;

      >div {
        margin-right: 14px;
        width: 72px;
        height: 24px;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        line-height: 20px;
        border: 1px solid #2586f5;
        text-align: center;
        cursor: pointer;
        font-size: 12px;
        font-family: MicrosoftYaHei-, MicrosoftYaHei;
        font-weight: normal;
        color: #2586f5;
      }

      >div:last-child {
        margin-right: 0;
      }
    }

    .zhang_item_last {
      flex-shrink: 0;
      // width: 40px;
      font-size: 12px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #aaaaaa;
      cursor: pointer;

      .iconfont {
        font-size: 12px;
      }
    }
  }

  .chapter_items {
    // height: 86px;
    margin-bottom: 0;
    border-bottom: 1px dotted #eeeeee;

    .right_box {
      align-items: start;
      // margin-right: 42px;

      .btn_item {
        margin-top: 10px;
      }
    }

    .left_box {
      margin-left: 30px;

      .title_box {
        // margin-bottom: 27px;
      }

      .title {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
    }

    .progress_box {
      margin-top: 11px;
    }
  }
}

.tab_box {
  width: 412px;
  height: 38px;
  border-radius: 6px;
  padding: 4px;
  background-color: #f5f7f9;
  display: flex;
  justify-content: space-around;
  position: relative;

  .btn_item {
    width: 132px;
    height: 30px;
    line-height: 30px;
    border-radius: 6px;
    font-size: 14px;
    text-align: center;
    color: #333333;
    position: relative;
    z-index: 1;
    cursor: pointer;
  }

  .on {
    color: #4a6af0;
    font-weight: 800;
  }

  .sliding_block {
    width: 132px;
    height: 30px;
    border-radius: 6px;
    background-color: #ffffff;
    position: absolute;
    left: 10px;
    transition: 200ms;
  }
}

::-webkit-scrollbar {
  width: 0 !important;
}

.tags {
  border: 1px solid #fe6e0e !important;
  color: #fe6e0e !important;
  background-color: rgba(254, 110, 14, 0.1);
}

.bdn {
  border: none !important;
  // background: rgba(254, 110, 14, 0.1) !important;
}

.lookBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .ifLook {
    width: 500px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;

    .contents {
      width: 380px;
      margin: 20px auto;

      &>span {
        color: red;
      }
    }

    .button {
      width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      border-radius: 10px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
}

.noAttendan {
  .btn_item {
    border: 1px solid #f1f1f1 !important;
    color: #666 !important;
  }
}

.zb {

  background: #F8F9FA;
}
</style>
